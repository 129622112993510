// Component .. event DetailsMain
import React, { useRef } from "react";
import MapButton from "./MapButton";
import BackButton from "./BackButton";
import MapPreview from "./MapPreview";
import TagsList from "./TagsList";
import { useNavigate } from "react-router-dom";

const EventDetailsMain = ({ data }) => {
  // console.log('received data on main :' ,data);
  console.log(data.duration);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/discover");
  };
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const handleRedirect = (url) => {
    window.location.href = url;
  };
  // console.log('Parsed Links:', data.organizerDetails.link);
  return (
    <div id="event-details-main" className="event-details-main-container">
      <BackButton data={{ text: "Back to Events", onClick: handleBackClick }} />

      <div className="event-details-main">
        <div className="left-side">
          <div className="basic-information">
            <h1 className="heading">{data.heading}</h1>
            {/* <h2 className="subheading">{data.location}</h2> */}
            <div className="location-section">
              <img src={iconPath + "map-pin-black.svg"} />
              <p className="location">{data.location}</p>
            </div>
            <div className="location-section">
              <img src={iconPath + "calendar-black.svg"} />
              <p className="location">{`${data.date}, ${data.time}`}</p>
            </div>
            <p className="description">{data.summary}</p>
          </div>
          <div className="starting-price-banner-mobile">
            <h3 className="banner-heading">Tickets starting at</h3>
            <p className="price">{`Rs. ${data.ticket_price}`}</p>
          </div>
          <div className="event-information">
            <br></br>
            <br></br>

            <h2 className="heading">Event Information</h2>
            <div className="info-container">
              <div className="container">
                <img src={iconPath + "clock-purple.svg"} />
                <div className="content">
                  <h1>Duration</h1>
                  <p className="description">{data.duration}</p>
                </div>
              </div>
              <div className="container">
                <img src={iconPath + "people-purple.svg"} />
                <div className="content">
                  <h1>Audience</h1>
                  <p className="description">{`This event is suitable for ${data.audience}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="starting-price-banner">
            <h3 className="heading">Tickets starting at</h3>
            <p className="price">{`Rs. ${data.ticket_price}`}</p>
          </div>
          <MapPreview className={"map-preview-pc"} mapLink={data.venue} />
        </div>
      </div>
      <div className="event-description">
        <h2 className="heading">Description</h2>
        <p className="subheading">{data.description}</p>
      </div>
      <div className="tags">
        <h2 className="heading">Tags</h2>
        <TagsList tags={data.tags} />
      </div>
      <MapPreview className={"map-preview-mobile"} mapLink={data.venue} />
      <div className="contact-share">
      <div className="left-side">
        <h2 className="heading">Organizer Contact</h2>
        <p className="subheading">{`Name: ${data.organizerDetails.name}`}</p>
        <p className="subheading">{`Phone Number: ${data.organizerDetails.contact}`}</p>

        {data.organizerDetails.link.insta && (
          <p className="subheading">
            Instagram:
            <a
              href={`https://instagram.com/${data.organizerDetails.link.insta}`}
              className="subheading"
            >{` @${data.organizerDetails.link.insta}`}</a>
          </p>
        )}

        {data.organizerDetails.link.fb && (
          <p className="subheading">
            Facebook:
            <a
              href={`https://facebook.com/${data.organizerDetails.link.fb}`}
              className="subheading"
            >{` @${data.organizerDetails.link.fb}`}</a>
          </p>
        )}

        {data.organizerDetails.link.website && (
          <p className="subheading">
            Website:
            <a href={data.organizerDetails.link.website} className="subheading">
              {` ${data.organizerDetails.link.website}`}
            </a>
          </p>
        )}
      </div>

      <div className="right-side">
        <h1 className="heading">Share with friends</h1>
        <div className="social-icons">
          {data.organizerDetails.link.fb && (
            <button
              onClick={() =>
                handleRedirect(
                  `https://www.facebook.com/${data.organizerDetails.link.fb}`
                )
              }
            >
              <img src={iconPath + "facebook.svg"} alt="Facebook" />
            </button>
          )}

          {data.organizerDetails.link.wa && (
            <button
              onClick={() =>
                handleRedirect(
                  `https://www.whatsapp.com/${data.organizerDetails.link.wa}`
                )
              }
            >
              <img src={iconPath + "whatsApp.svg"} alt="WhatsApp" />
            </button>
          )}

          {data.organizerDetails.link.linkedin && (
            <button
              onClick={() =>
                handleRedirect(
                  `https://www.linkedin.com/${data.organizerDetails.link.linkedin}`
                )
              }
            >
              <img src={iconPath + "linkedin.svg"} alt="LinkedIn" />
            </button>
          )}

          {data.organizerDetails.link.insta && (
            <button
              onClick={() =>
                handleRedirect(
                  `https://www.instagram.com/${data.organizerDetails.link.insta}`
                )
              }
            >
              <img src={iconPath + "instagram.svg"} alt="Instagram" width="50" />
            </button>
          )}
        </div>
      </div>

      </div>
    </div>
  );
};

export default EventDetailsMain;

import React, { useState, useEffect } from "react";
import WhiteButton from "./WhiteButton";

const EventBanner = ({ data }) => {
  const imagePath = process.env.PUBLIC_URL + "/";
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const handleCardClick = (id) => {
    window.location.href = `/event-details/${id}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Change the duration according to your preference (5 seconds = 5000 milliseconds)
    return () => clearInterval(interval);
  }, [slide]);

  return (
    <div className="event-banner-container">
      <div className="carousel-container">
        <div className="carousel">
          {/* <img onClick={prevSlide} className="arrow arrow-left" /> */}
          {data.map((item, idx) => {
            return (
              <>
                {/* <div className={slide === idx ? "slide" : "slide slide-hidden"} style={{ backgroundImage: `url(${item.imageSrc})` }}>
                                <h6>{item.heading}</h6>
                                <WhiteButton content={item.buttonContent} dest={item.dest}/>
                            </div> */}
                <img
                  className={slide === idx ? "slide" : "slide slide-hidden"}
                  style={{ cursor: "pointer" }}
                  src={item.imageUrl}
                  onClick={() => handleCardClick(item.id)}
                />
                {/* <img src={`${item.imageSrc}`} className={slide === idx ? "slide" : "slide slide-hidden"} /> */}
                {/* <img
                                src={item.src}
                                alt={item.alt}
                                key={idx}
                                className={slide === idx ? "slide" : "slide slide-hidden"}
                            /> */}
              </>
            );
          })}
          {/* <img
                    onClick={nextSlide}
                    className="arrow arrow-right"
                /> */}
          <span className="indicators">
            {data.map((_, idx) => {
              return (
                <button
                  key={idx}
                  className={
                    slide === idx ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(idx)}
                ></button>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventBanner;
